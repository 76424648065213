'use strict';

$(document).ready(function(){

    $('.flexslider').flexslider({
      animation: "slide",
      directionNav: false,
      minItems: 1,
      maxItems: 4
    });

});
